@media only screen and (min-width: 1536px) and (-webkit-device-pixel-ratio: 1.25),
       only screen and (min-width: 1536px) and (max-width: 1919px),
       only screen and (min-width: 1367px) and (max-width: 1440px),
       only screen and (min-width: 1281px) and (max-width: 1366px){

  //Global class
  .tbc-button,
  .MuiInputBase-root,
  .MuiMenuItem-root,
  .MuiButtonBase-root,
  .MuiFormLabel-root,
  .MuiAutocomplete-listbox{
    font-size: 0.8rem !important;
  }

  .MuiTypography-root,
  .field-inner-wrapper,
  .filter-label,
  .title-label{
    font-size: 0.9rem !important;
  }
  .MuiBadge-badge{
    font-size: 0.65rem !important;
    min-width: 30px !important;
  }

  .MuiAccordionSummary-content{
    font-size: 1rem !important;
  }

  .dt-pagination-container-right-center{
    font-size: 0.8rem !important;
  }

  .Toastify{
    .Toastify__toast-body{
      font-size: 0.8rem !important;
    }
  }

  .MuiInputBase-input::placeholder {
    font-size: 0.8rem !important;
  }

  // Login Page
  .formContainer{
    .formSubContainer{
      h1{
        font-size: 2rem !important;
      }
      p{
        font-size: 1.6rem !important;
      }
    }
  }

  //sidebar
  .tbc-sidebar-custom{
    .MuiDrawer-paper{
      top: 110px !important;
    }
    .MuiListSubheader-root,
    .MuiTypography-root{
      font-size: 0.9rem !important;
    }
   
  }


  //breadcrumbs
  .breadcrumbs-container{
    height: 30px !important;
    .MuiBreadcrumbs-root{
      font-size: 0.8rem !important;
    }
  }



  //Landing Page 
  .home.wrapper{
    height: calc(100vh - 120px) !important;
    padding-left: 0px !important;
    .tile-container{
      margin-top: 10px;
      .block{
        max-width: 11vw !important;
        min-height: 135px !important;
        .main-icons{
          width: 3.7vw !important;
        }
      }
      
    }
  }

  .tile-layout{
     padding-top: 0px !important;
     .tile{
      width: 11vw !important;
      .block{
        min-height: 135px !important;
        .main-icons{
          width: 4vw !important;
        }
      }
     }
  }

  .wrapper{
    height: auto !important;
  }

  //Daily updates 
  .page-daily-update{
    max-width: -webkit-fill-available;
    .filter-group{
      gap: 1rem !important;
    }
  }
  .form-daily-update{
    .filter-wrapper{
      display: flex;
      align-items: center;
      gap: 1rem;
      .filter-group{
        width: 75% !important;
      }
      .btn-group{
        width: 25% !important;
        display: flex;
        justify-content: flex-end;
      }
    }
    
  }

  //ECI 
  .top-wrapper{
    gap: 2rem !important;
      .state-wrapper,
      .district-container,
      .assembly-container{
        padding: 0px !important;
      }
  }
  .details-wrapper{
    .name-wrapper{
      gap: 1rem !important;
      padding: 0px !important;
      .flex {
        width: 30% !important;
        .tbc-textfield{
          width: 100%;
        }
      }
    }
    .age-gender-wrapper{
      padding: 0px !important;
      .age-city-container{
        width: 60% !important;
      }
      .gender-container,
      .data-source-container{
        width: 20% !important;
      }
    }
    .name-wrapper-nl{
      padding: 0px !important;
      margin-bottom: 10px;
    }
  }


  
}


@media only screen and (min-width: 1025px) and (max-width: 1280px){

  //Global class
  .tbc-button,
  .MuiInputBase-root,
  .MuiMenuItem-root,
  .MuiButtonBase-root,
  .MuiFormLabel-root,
  .MuiAutocomplete-listbox{
    font-size: 0.75rem !important;
  }

  .xpna-table-wrapper .table-container{
    thead{
      th{
        font-size: 0.8rem !important;
      }
    }
    tbody{
      td{
        font-size: 0.75rem !important;
      }
    }
  }

  .MuiTypography-root,
  .field-inner-wrapper,
  .filter-label,
  .title-label{
    font-size: 0.75rem !important;
  }

  .MuiAccordionSummary-content{
    font-size: 1rem !important;
  }

  .dt-pagination-container-right-center,
  .dt-next-page-link{
    font-size: 0.75rem !important;
  }

  .Toastify{
    .Toastify__toast-body{
      font-size: 0.75rem !important;
    }
  }

  .MuiInputBase-input::placeholder {
    font-size: 0.75rem !important;
  }

  .MuiBadge-badge{
    font-size: 0.6rem !important;
  }

  // Login Page
  .formContainer{
    .formSubContainer{
      h1{
        font-size: 2rem !important;
      }
      p{
        font-size: 1.6rem !important;
      }
    }
  }

  //sidebar
  .tbc-sidebar-custom{
    .MuiDrawer-paper{
      top: 110px !important;
    }
    .MuiListSubheader-root,
    .MuiTypography-root{
      font-size: 0.8rem !important;
    }
   
  }


  //breadcrumbs
  .breadcrumbs-container{
    height: 30px !important;
    .MuiBreadcrumbs-root{
      font-size: 0.8rem !important;
    }
  }

  //ck editor
  .ck.ck-icon{
    font-size: 0.6rem !important;
  }
  .ck-button__label{
    font-size: 0.8rem !important;
  }



  //Landing Page 
  .home.wrapper{
    height: calc(100vh - 120px) !important;
    padding-left: 0px !important;
    .tile-container{
      margin-top: 10px;
      .block{
        max-width: 13vw !important;
        min-height: 140px !important;
        .main-icons{
          width: 3.7vw !important;
        }
      }
      
    }
  }

  .tile-layout{
     padding-top: 0px !important;
     .tile{
      width: 10vw !important;
      height: 110px !important;
      .block{
        min-height: 110px !important;
        align-items: center;
        justify-content: center;
        display: flex;
        .tiles{
          padding: 0px !important;
          text-align: center;
          .main-icons{
            width: 3vw !important;
            margin: 0px !important;
          }
          .Text-subText{
            font-size: 0.8rem !important;
          }
        }
      }
     }
  }

  .wrapper{
    height: auto !important;
  }

  //dashboard 
  .dashboard-header{
    .file-count{
      margin-top: -15px;
      height: 13vh;
      width: 20% !important;
    }
  }

  //proposal management 
  .proposal-management{
    .filter-wrapper{
      display: block !important;
      .filter-group{
        gap: 1rem !important;
        max-width: 100% !important;
      }
    }
    .btn-proposal-container{
      margin-top: 20px;
    }
    .proposal-wrapper{
      margin-top: 0px !important;
    }
  }

  //open case 
  .page-content{
    .open-case-writer{
      padding: 0px !important;
    }
  }

  .open-case-writer{
    .header-label{
      display: none !important;
    }
    .ck.ck-editor.ck-reset.ck-rounded-corners{
      width: auto !important;
    }
    .filter-group-with-label-wrapper{
      display: flex !important;
      gap: 2rem !important;
      .title-wrapper{
        width: 100% !important;
        .title-label{
          width: 16% !important;
        }
        .title-field{
          width: 84% !important;
        }
      }
      .field-wrapper{
        width: 50% !important;
        .filter-label{
          width: 35% !important;
        }
        .filter-field{
          width: 65% !important;
        }
      }
      .filter-inner-group{
        width: 50% !important;
        .filter-inner-label{
          width: 35% !important;
        }
        .filter-inner-field{
          width: 65% !important;
        }
      }
    }
  }

  //Daily updates 
  .page-daily-update{
    max-width: -webkit-fill-available;
    .filter-group{
      gap: 1rem !important;
    }
    .filter-wrapper{
      display: flex;
      width: 100%;
      flex-direction: column;
      .btn-group{
        display: flex;
        justify-content: end;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

  }

  //ECI 
  .top-wrapper{
    gap: 2rem !important;
      .state-wrapper,
      .district-container,
      .assembly-container{
        padding: 0px !important;
      }
  }
  .details-wrapper{
    .name-wrapper{
      gap: 1rem !important;
      padding: 0px !important;
      .flex {
        width: 30% !important;
        .tbc-textfield{
          width: 100%;
        }
      }
    }
    .age-gender-wrapper{
      padding: 0px !important;
      display: block !important;
      .age-city-container{
        width: 100% !important;
      }
      .gender-container,
      .data-source-container{
        display: flex !important;
        width: 100% !important;
        justify-content: flex-start !important;
      }
    }
    .name-wrapper-nl{
      padding: 0px !important;
      margin-bottom: 10px;
    }
    .state-wrapper,
    .district-container,
    .assembly-container,
    .district-container{
      width: 50% !important;
      padding: 0px !important;
    }
  }
  .captcha-wrapper{
    .captcha-container{
      width: 100% !important;
    }
  }


  //MCA 
  .company-wrapper{
    .filter-group{
      gap: 1rem !important;
      align-items: center !important;
      .search{
        width: 65% !important;
        .search-bar{
           width: 100% !important;
        }
      }
      .btn-enter{
        width: 15% !important;
      }
      .state-wrapper{
        width: 20% !important;
      }
    }
  }

  //CIBIL
  .cibil-search-input{
    .details-section{
      .details-wrapper{
        padding: 20px !important;
        .state-wrapper{
          width: 50% !important;
          .tbc-textfield,.tbc-select,.MuiAutocomplete-root{
            width: 100% !important;
            min-width: 100% !important;
          }
        }
      }
    }
  }

  //Haryana revenue 
  .revenue-search-input{
    .filter-wrapper{
      display: flex !important;
      gap: 0.1rem !important;
    }
    .button-wrapper{
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
      margin-top: 20px !important;
    }
  }

  //PPQS
  .ppqs-search-input{
    .search-fields-container{
      .filter{
        width: 33% !important;
      }
      .btn-group{
        justify-content: end;
        width: 35% !important;
      }
    }
  }



}

// For tablets (up to 1024px)
@media only screen and (max-width: 1024px) {
  // styles for devices up to 1024px wide
}

// For mobile devices (up to 768px)
@media only screen and (max-width: 768px) {
  // styles for devices up to 768px wide
}

// For smaller mobile devices (up to 480px)
@media only screen and (max-width: 480px) {
  // styles for devices up to 480px wide
}
